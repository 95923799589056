<template>
  <div class="contianer">
    <sit-header :left="logo" :right="logo1"/>
    <div class="kd-page page-home">
      <sit-banner  :data="upBanner"/>
      <sit-type ref="type"/>
      <sit-content ref="content" :data="centerBanner" :bottom="bottomBanner" :left="leftBanner" :right="rightBanner"/>
    </div>
   
  </div>
</template>

<script>
import sitHeader from './components/header'
import sitBanner from './components/banner'
import sitType from './components/type'
import sitContent from './components/content'
import{getBcAd} from './utils/api'

export default {
  name: 'App',
  components: {
    sitHeader,sitBanner,sitType,sitContent
  },
  data(){
    return {
      upBanner:[],
      logo:{},
      logo1:{},
      centerBanner:[],
      bottomBanner:[],
      leftBanner:{},
      rightBanner:{}
    }
  },
  methods:{
    fourUpdate(){
      this.$refs.type.getInfo()
      this.$refs.content.getInfo()
      // setTimeout(()=>{
      //   this.fourUpdate()
      // },1*5*1000)
    },
    async getBcAd(){
      let res = await getBcAd()
      this.logo = (res[1]&&res[1].length>0)?res[1][0]:{}
      this.logo1 = (res[2]&&res[2].length>0)?res[2][0]:{}
      this.upBanner = res[3]
      this.centerBanner = res[4]
      this.bottomBanner = res[5]
      this.leftBanner = res[6][0]
      this.rightBanner = res[7][0]
    },
  },
  mounted(){
    this.fourUpdate()
    this.getBcAd()
    // getXglhc().then(res=>{
    //   console.log(res)
    // })
  }
}
</script>

<style lang="less">
@media only screen and (min-width: 960px){
  .bet-form, .chat-textinput, .follow-betting, .game-list-wrapper, .kd-head, .kd-loading, .kd-menu-wrapper, .kd-page, .kd-toolbar, .lottery-wrapper, .pcdd-betting-panel, .pcdd-chat-footer, .pcdd-game-head, .promptbox-wrapper, .room-latest-history-list, .waiting{
    max-width: 980px;
    margin: 0 auto;
  }
  
}
.kd-page{
  min-width: 320px;
    overflow-x: hidden;
    max-width: 960px;
    background-color: #fff;
    top: 9px;
    padding-bottom: 20px;
    border-radius: 0 0 8px 8px;
}
a{
  background: none;
  text-decoration: none;
  color: #e83f3f;
  cursor: pointer;
  font-family: microsoft yahei,Open Sans,Arial,sans-serif;
}

</style>
