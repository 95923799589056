<template>
    <div id="ads_1">
        <a :href="item.ad_link" target="_blank" v-for="(item,index) in data" :key="index" :alt="item.ad_title">
            <img :src="item.ad_image">
        </a>
        <!-- <a href="https://m.dswnn.cn/#/lowerLogin/3A58goHA1px7T" target="_blank">
            <img src="../assets/ds960x250-1.gif">
        </a> -->
    </div>

</template>
<script>
export default {
    name:'sit-banner',
    props:{
        data:{
            type:Array,
            default(){
                return []
            }
        }
    }
}
</script>
<style lang="less" scoped>
#ads_1{
    margin: 55px .5rem .5rem;
    a{
        background: none;
        text-decoration: none;
        color: #e83f3f;
        cursor: pointer;
        font-family: microsoft yahei,Open Sans,Arial,sans-serif;
        img{
            border: 0;
            max-width: 100%;
            margin: .2rem 0 0;
        }
    }
}
</style>