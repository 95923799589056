import axios from "@/utils/require";

/**
 * 获取最新一期六合彩
 * @param {*} data
 */

export function getXglhc(params = {}) {
  return axios({
    url: "/nsbc/getXglhc",
    method: "get",
    params
  });
}


/**
 * 获取最新一期六合彩
 * @param {*} data
 */

export function getJnd28(params = {}) {
  return axios({
    url: "/nsbc/getJnd28",
    method: "get",
    params
  });
}


/**
 * 获取急速28
 * @param {*} data
 */

export function getJs28(params = {}) {
  return axios({
    url: "/nsbc/getJs28",
    method: "get",
    params
  });
}

/**
 * 获取图片
 * @param {*} data
 */

export function getBcAd(params = {}) {
  return axios({
    url: "/nsbc/getBcAd",
    method: "get",
    params
  });
}

