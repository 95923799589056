import axios from "axios";

import QS from "qs";

const baseURL = "https://bc.siwuxie.work/api";
// const baseURL = "/api";
const timeout = 30000;

export const responseHandler = response => {
  const res = response.data;
  console.log(response,"ajax 返回", res);
  if(res.code==1) return Promise.resolve(res.data)
  return Promise.reject(new Error(res.msg || "Error"));
};



export const errorHandler = error => {
  console.log("err", error);
  error = error.toString()
  return Promise.reject(error);
};

const service = axios.create({
  baseURL,
  withCredentials: true,
  timeout,
  transformRequest: [
    (data = {}, headers) => {
      if (
        headers["Content-Type"] &&
        headers["Content-Type"].indexOf("application/json") !== -1
      ) {
        return JSON.stringify(data);
      } else if (
        headers["Content-Type"] &&
        headers["Content-Type"].indexOf("multipart/form-data") !== -1
      ) {
        return data;
      }
      // 默认是 application/x-www-form-urlencoded'
      return QS.stringify(data, { arrayFormat: "brackets" });
    }
  ],
  paramsSerializer: function(params) {
    // 针对get请求
    return QS.stringify(params, { arrayFormat: "brackets" });
  }
});

// export const requestBefore = config => {
//   config.headers["X-CSRFToken"] = store.getters.token || getToken();
//   config.headers["Api-Version"] = "1.1";
//   return config;
// };

// service.interceptors.request.use(requestBefore, error => {
//   console.log(error);
//   return Promise.reject(error);
// });

service.interceptors.response.use(responseHandler, errorHandler);

export default service;

export { QS, baseURL, timeout };
